import {
  ReactDivAPIProvider as ReactDivAPIProviderProps,
  ReactDivFields
} from '@/modules/core/reactDivAPI/types/ReactDivAPI';
import {useCookies} from 'react-cookie';
import {ReactNode, useState, useEffect} from 'react';
import axios from 'axios';
import {reactDivAPIContext as ReactDivAPIContext} from '@/modules/core/reactDivAPI/contexts/reactDivAPIContext';
import Router, {NextRouter, useRouter} from 'next/router';
import map from 'lodash.map';
import pickBy from 'lodash.pickby';
import {getTimestampNow} from '@/modules/core/date/date';
import {createLogger} from '@/modules/core/logging/logger';


const fileLabel = 'modules/core/reactDivAPI/providers/reactDivAPIProvider.tsx';
const logger = createLogger({
  fileLabel,
});

export function ReactDivAPIProvider({
                                      reactDivClientID,
                                      reactDivClientProjectID,
                                      allUsers = [],  // NOTE readUsers onBuild
                                      allProjects = [], // NOTE readProjects onBuild
                                      allShells = [], // NOTE v1.0
                                      // @ts-ignore
                                      allShellMenuItems = [],
                                      allFields = [],
                                      allFieldSettings = [],
                                      allFieldValidations = [],
                                      allFieldAdvanced = [],
                                      children
                                    }: ReactDivAPIProviderProps) {

  const [cookie, setCookie, removeCookie] = useCookies([
    'access_token',
    'client_id',
    'loggedInUser',
    'activeUser'
  ]);
  // Users
  const [loggedInUserId, setLoggedInUserId] = useState(); // NOTE v1.0
  const [loggedInUser, setLoggedInUser] = useState(); // NOTE v1.0
  const [activeUserId, setActiveUserId] = useState(loggedInUserId); // NOTE v1.0
  const [activeUser, setActiveUser] = useState(); // NOTE v1.0
  const [selectedUserId, setSelectedUserId] = useState(); // NOTE v1.0
  const [selectedUser, setSelectedUser] = useState(); // NOTE v1.0
  const [allUsersData, setAllUsersData] = useState(allUsers); // NOTE v1.0
  // Projects
  const [loggedInUserProjectId, setLoggedInUserProjectId] = useState();
  const [loggedInUserProject, setLoggedInUserProject] = useState();
  const [activeUserProjectId, setActiveUserProjectId] = useState();
  const [activeUserProject, setActiveUserProject] = useState();
  const [allProjectsData, setAllProjectsData] = useState(allProjects); // NOTE v1.0

  const [userCreatedProjects, setUserCreatedProjects] = useState(allProjects);
  const [userCreatedProjectsMenuItems, setUserCreatedProjectsMenuItems] = useState([]);
  const [activeProject, setActiveProject] = useState('');
  // Shells
  const [allShellsData, setAllShellsData] = useState(allShells); // NOTE v1.0

  const [userCreatedShells, setUserCreatedShells] = useState(allShells);
  const [userCreatedShellsMenuItems, setUserCreatedShellsMenuItems] = useState(allShellMenuItems);
  const [activeShell, setActiveShell] = useState();
  const [activeShellId, setActiveShellID] = useState();
  const [activeSystemShell, setActiveSystemShell] = useState();
  const [activeSystemShellID, setActiveSystemShellID] = useState();
  // Fields
  const [reactDivFields, setReactDivFields] = useState(allFields);
  const [reactDivFieldSettings, setReactDivFieldSettings] = useState(allFieldSettings);
  const [reactDivFieldValidations, setReactDivFieldValidations] = useState(allFieldValidations);
  const [reactDivFieldAdvanced, setReactDivFieldAdvanced] = useState(allFieldAdvanced);
  const [activeReactDivField, setActiveReactDivField] = useState(false);
  const [activeReactDivFieldSettings, setActiveReactDivFieldSettings] = useState(allFieldSettings);
  const [activeReactDivFieldValidations, setActiveReactDivFieldValidations] = useState(allFieldValidations);
  const [activeReactDivFieldAdvanced, setActiveReactDivFieldAdvanced] = useState(allFieldAdvanced);
  // Shelled Fields
  const [currentAddReactionState, setCurrentAddReactionState] = useState('');
  const [allShelledFields, setAllShelledFields] = useState();
  const [allSystemShelledFields, setAllSystemShelledFields] = useState();
  // const [systemShelledFieldData, setSystemShelledFieldData] = useState(systemShelledFieldData)
  // Content
  const [activeContentId, setActiveContentId] = useState(false);
  // Content Entry
  const [activeContentEntryId, setActiveContentEntryId] = useState(false);


  // Users
  // Create
  const createUser = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('createUser: formData', formData); // eslint-disable-line no-console
    }
    // verify user client_id is available
    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/create/`, {
        credentials: {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          status: 'pending',
          type: formData.type
        },
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          company: formData.company,
          url: formData.url
        },
        reactDivConfig: {
          dashboard: {},
          webModule: {}
        }
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('createUser: data', data); // eslint-disable-line no-console
      }
      readUsers();
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
    }
  };
  // Read
  const readUsers = async () => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('readUsers:'); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/users/collect/`, config);
      if (response.status === 200) {
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('readUsers: data', data); // eslint-disable-line no-console
        }
        setAllUsersData(data.data);
        return true;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  };
  const readUser = async (userId) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('readUser: userId', userId); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/collect/${userId}`,
        config
      );
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('readUser: data', data); // eslint-disable-line no-console
        }
        return data.data;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  };
  // Update
  const updateUserActiveUser = async (userId) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateUserActiveUser: userId', userId); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const putData = {
        activeUser: activeUserId,
      };
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/active_user/change/${userId}`,
        putData,
        config,
      );

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('successfully updateUserActiveUser(userID): userId, data', userId, data); // eslint-disable-line no-console
        }
        return data.data;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };  // NOTE v1.0
  const updateUserActiveProject = async (userId) => {
    console.log('updating userActiveProject', userId);
    console.log('iamthevalue readUser userID', userId);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const putData = {
        activeProject: activeUserProjectId,
      };
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/active_project/change/${userId}`,
        putData,
        config,
      );

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        console.log('readUser response', response);
        const {data} = response;
        console.log('readUser response data', data);
        // setSelectedUserData(data.data);
        return data.data;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  }; // NOTE v1.0
  const updateUser = async (formData, userId) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateUser: userId', userId, formData); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const putData = {
        // credentials: activeUserId,
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          // profileImage: "",
          // company: "",
          // url: ""
        }
      };
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/change/${userId}`,
        putData,
        config,
      );
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('successfully updateUserActiveUser(userID): userId, data', userId, data); // eslint-disable-line no-console
        }
        return data.data;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  }; // NOTE v1.0

  // Web Module
  // Projects
  const createProject = async (formData) => {
    console.log('submitted - creating project', formData);
    // verify user client_id is available
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/projects/create/`, {
        ownership: {
          //@ts-ignore
          client_id: `${cookie.client_id}`
        },
        domain: formData.domain,
        company: formData.company,
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);
      readProjects();
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
    }
  };
  const readProjects = async () => {
    console.log('fetching all projects from reactDiv api');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/projects/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setUserCreatedProjects(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const readProject = async (singuleAPIID) => {
    console.log('readProject');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/project/collect/${singuleAPIID}`, config);
      // console.log('readShell reseponse', response)
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('readShell success stuff', response)
        const {data} = response;
        console.log('readProject more success stuff', data);
        // setActiveShell(data.data);
        // setActiveShellID(data.data._id);

        return data.data;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  }; // NOTE Dashboard Only

  // Shells
  const createShell = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('createShell: formData', formData); // eslint-disable-line no-console
    }
    const now = getTimestampNow();
    try {
      const preparedData = {
        ownership: {
          system: true,
        },
        type: formData.type,
        label: formData.label,
        singleApiId: formData.singleApiId,
        groupApiId: formData.groupApiId,
        description: formData.description && formData.description || null,
        // fields: {
        //   entries: [],
        // },
        // dependencies: {
        //   entries: [],
        // },
        readWrite: {
          created_at: now,
          created_by: activeUserId,
          updated_at: now,
          updated_by: activeUserId,
          published_at: now,
          published_by: activeUserId
        }
      };
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('createShell: preparedData', preparedData); // eslint-disable-line no-console
      }
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shell/create`,
        preparedData,
        {headers: {Authorization: `Bearer ${cookie.access_token}`}}
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('createShell: response data', data); // eslint-disable-line no-console
      }
      readShells();
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
    }
  }; // NOTE v0.8
  const readShells = async () => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('readShells:'); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      };
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shells/collect/`,
        config
      );
      if (response.status === 200) {
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('readShells: data', data); // eslint-disable-line no-console
        }
        // setUserCreatedShells(data.data);
        return true;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  }; // // NOTE v0.8
  const readShell = async (singleApiId) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('readShell: singleApiId', singleApiId); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      };
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shell/collect_by_single_api_id/${singleApiId}`,
        config
      );
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('readShell success stuff', response)
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('readShell: data', data); // eslint-disable-line no-console
        }
        return data.data;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  }; // // NOTE v0.8
  const updateShellFields = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateShellFields: formData', formData, activeReactDivField); // eslint-disable-line no-console
    }
    const now = getTimestampNow();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const preparedData = {
        // credentials: activeUserId,
        fields: {
          fieldId: activeReactDivField.fieldId, // NOTE WHere from?
          fieldType: formData.fieldType,
          fieldPosition: '0',
          fieldLabel: formData.displayName,
          settings: {
            singleApiId: formData.apiID,
            description: formData.description,
            allowListValues: formData.allowMultipleValues
          },
          readWrite: {
            created_at: now,
            created_by: activeUserId,
            updated_at: now,
            updated_by: activeUserId,
            published_at: now,
            published_by: activeUserId
          }
        },
        updated_at: now,
        updated_by: activeUserId,
        published_at: now,
        published_by: activeUserId
      };
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('updateShellFields: preparedData', preparedData); // eslint-disable-line no-console
      }
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shell/add_field_by_id/${activeShellId}`,
        preparedData,
        config,
      );
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('successfully updateShellFields(userID): activeShellId, data', activeShellId, data); // eslint-disable-line no-console
        }
        return data.data;
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  };


  // NOTE Dashboard Only ADMIN
  // const readSystemShell = async (singularAPIID) => {
  //   console.log('readSystemShell');
  //   try {
  //     const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
  //     const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/systemShell/collect_by_singular_api_id/${singularAPIID}`, config);
  //     // console.log('readShell reseponse', response)
  //     if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
  //       // console.log('readShell success stuff', response)
  //       const {data} = response;
  //       // console.log('readShell more success stuff', data)
  //       setActiveSystemShell(data.data);
  //       setActiveSystemShellID(data.data._id);
  //
  //       // return true;
  //     }
  //     return false;
  //
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   }
  // }; // NOTE Dashboard Only
  // // NOTE Dashboard Only ADMIN
  // const createSystemShell = async (formData) => {
  //   console.log('clicked - creating system shell', formData);
  //   // return
  //   try {
  //
  //     const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/systemShell/create`, {
  //       ownership: {
  //         client_id: '630dfcbd97f8835a69f9f991', // TODO not hardcode currently vincent
  //         project_id: '630a4605c7703c23a8cc9a84', // TODO not hardcode currently central brisbane dental
  //       },
  //       type: formData.type, // NOTE From List
  //       name: formData.name,
  //       singularAPIID: formData.singularAPIID,
  //       pluralisedAPIID: formData.pluralisedAPIID,
  //       description: formData.description && formData.description || null,
  //       dependencyFor: formData.dependencyFor, // NOTE From List
  //       dependencyType: formData.dependencyType, // NOTE From List
  //     }, {
  //       headers: {Authorization: `Bearer ${cookie.access_token}`}
  //     });
  //
  //     const data = response?.data;
  //     // const accessToken = data?.access_token;
  //     console.log('data', data);
  //     // readShells()
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // Fields
  const createField = async () => {

  };
  const readFields = async () => {
    console.log('readFields');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.REACTDIV_API_URL}reactDiv/web/field`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        console.log('more success stuff', data);
        setReactDivFields(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const readFieldSettings = async () => {
    console.log('readFields');
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(`${process.env.REACTDIV_API_URL}reactDiv/web/field/settings/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        console.log('web field settings', data);
        setReactDivFieldSettings(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  // Shelled Fields?
  const createShelledFields = async (formData, event, additionalData) => {
    console.log('clicked - creating shelled field', activeShellId);
    const preparedData = {
      fieldType: formData.fieldType,
      fieldPosition: 0,
      ownership: {
        system: false,
        client_id: activeUserId,
        project_id: activeUserProjectId,
        shell_id: activeShellId
      },
      settings: {
        displayName: formData.displayName,
        apiID: formData.apiID,
        description: formData.description,
        // useAsTitleField: formData.useAsTitle,
        allowMultipleValues: formData.allowMultipleValues,
        // localizeField: formData.localizeField
      },
      // validations: {
      //   makeFieldRequired: formData.makeFieldRequired,
      //   setFieldAsUnique: formData.setFieldAsUnique,
      //   limitCharacterCount: formData.limitCharacterCount,
      //   matchASpecificPattern: formData.matchASpecificPattern,
      //   restrictASpecificPattern: formData.restrictASpecificPattern,
      // },
      // advanced: {
      //   setInitialValue: formData.setInitialValue,
      //   visibility: formData.fieldVisibility,
      // }
    };
    console.log('prepared - creating shelled field', preparedData);
    // return
    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shelled_field/create`, preparedData, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });
      const data = response?.data;
      console.log('shelled field data', data);
      readShelledFields();
    } catch (err) {
      console.log(err);
    }
  };
  const readShelledFields = async () => {
    console.log('readShelledFields');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shelled_field/collect_all/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setAllShelledFields(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };

  // Content
  const createContentEntry = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('createContentEntry: formData', formData); // eslint-disable-line no-console
    }
    const now = getTimestampNow();
    try {
      const preparedData = {
        ownership: {
          system: 'false',
          client_id: activeUserId,
          project_id: activeUserProjectId,
          shell_id: activeShellId
        },
        label: formData.label,
        singleApiId: formData.singleApiId,
        groupApiId: formData.groupApiId,
        status: "draft",
        contentData: [],
        readWrite: {
          created_at: now,
          created_by: activeUserId,
          updated_at: now,
          updated_by: activeUserId,
          published_at: now,
          published_by: activeUserId
        }
      }
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('createContentEntry: preparedData', preparedData); // eslint-disable-line no-console
      }
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/create`,
        preparedData,
        {
          headers: {Authorization: `Bearer ${cookie.access_token}`}
        }
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('createContentEntry: response data', data); // eslint-disable-line no-console
      }
      // Router.push(`/dashboard/content-editor/${formData.activeShellId}/${formData.singleApiId}`);
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
    }
  };
  const readContentEntry = async (entryName) => {
    console.log('readContentEntry');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/collect_by_ownership_and_single_api_id/?client_id=${activeUserId}&project_id=${activeUserProjectId}&shell_id=${activeShellId}&single_api_id=${entryName}`, config);
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('readShell success stuff', response)
        const {data} = response;
        setActiveContentId(data.data._id);
        return data.data;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const updateContentEntry = async (formData) => {
    console.log('submitted - updating content entry activeContentId', formData,);
    const contentData = [];
    Object.values(map((formData), (v, k) => {
      contentData.push({
        shelled_field_id: k,
        value: v
      });
    }));
    const preparedData = {
      contentData: contentData
    };
    console.log('preparedData', preparedData);
    try {
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/change_by_content_id/${activeContentId}`,
        preparedData,
        {
          headers: {Authorization: `Bearer ${cookie.access_token}`}
        });
      const data = response?.data;
      // Router.push(`/dashboard/content-editor/shells/pages/${formData.label.replace(/\s+/g, '-').toLowerCase()}`);
    } catch (err) {
      console.log(err);
    }
  };
  const updateContentEntryDependency = async (formData) => {
    console.log('submitted - updating content entry activeContentId', formData,);
    const contentData = [];
    Object.values(map((formData), (v, k) => {
      contentData.push({
        shelled_field_id: k,
        value: v
      });
    }));
    const preparedData = {
      pageSEOSettings: contentData
    };
    console.log('preparedData', preparedData);
    try {
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/change_dependencies_by_content_id/${activeContentId}`,
        preparedData,
        {
          headers: {Authorization: `Bearer ${cookie.access_token}`}
        });
      const data = response?.data;
      // Router.push(`/dashboard/content-editor/shells/pages/${formData.label.replace(/\s+/g, '-').toLowerCase()}`);
    } catch (err) {
      console.log(err);
    }
  };

  // Content Entry
  // const readContentEntry = async () => {
  //   console.log('readContentEntry');
  //   try {
  //     const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
  //     const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/collect_by_ownership/?client_id=${activeUserId}&project_id=${activeUserProjectId}&shell_id=${activeShellId}`, config);
  //     if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
  //       // console.log('readShell success stuff', response)
  //       const {data} = response;
  //       setActiveContentId(data.data._id);
  //       return data.data
  //     }
  //     return false;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   }
  // };


  // onLoad
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('useEffect,[loggedInUser]: loggedInUser', loggedInUserId); // eslint-disable-line no-console
    }
    if (loggedInUserId) {
      const fetchData = async () => {
        const data = await readUser(loggedInUserId); // NOTE v1.0 Probably read usersProject, usersetc
        setLoggedInUser(data);
        setActiveUserId(data.reactDivConfig.dashboard.activeUser);
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('fetchData, readUser(loggedInUser): data', data); // eslint-disable-line no-console
        }
      };
      fetchData().catch(console.error);
    }
  }, [loggedInUserId]);
  // Users
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('cookie: cookie', cookie); // eslint-disable-line no-console
    }
    if (cookie.loggedInUser) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('cookie: loggedInUser', cookie.loggedInUser); // eslint-disable-line no-console
      }
      const updateCookies = async () => {
        setLoggedInUserId(cookie.loggedInUser.client_id);
      };
      updateCookies().catch(console.error);
    } else if (cookie.activeUser) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('cookie: activeUser', cookie.activeUser); // eslint-disable-line no-console
      }
      const updateCookies = async () => {
        setActiveUserId(cookie.activeUser.id);
      };
      updateCookies().catch(console.error);
    }
  }, [cookie]);
  // Update activeUser Data on change activeUser
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('useEffect,[activeUserId]: activeUserId', activeUserId); // eslint-disable-line no-console
    }
    if (activeUserId) {
      const updateData = async () => {
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('updateData: activeUserId', activeUserId); // eslint-disable-line no-console
        }
        await setCookie('activeUser', JSON.stringify({id: activeUserId}), {
          path: '/',
          maxAge: 36000,
          sameSite: true,
        });
        await updateUserActiveUser(loggedInUserId);
      };
      const fetchData = async () => {
        const data = await readUser(activeUserId);
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('fetchData readUser(activeUserId): data', data); // eslint-disable-line no-console
        }
        setActiveUser(data);
        setActiveUserProjectId(data.reactDivConfig.dashboard.activeProject);
      };
      updateData().catch(console.error);
      fetchData().catch(console.error);
    }
  }, [activeUserId]);
  // Update selectedUser Data on change activeUser
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('useEffect,[selectedUserId]: selectedUserId', selectedUserId); // eslint-disable-line no-console
    }
    if (selectedUserId) {
      // const updateData = async () => {
      //   if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      //     logger.debug('updateData: selectedUserId', selectedUserId); // eslint-disable-line no-console
      //   }
      //   await setCookie('activeUser', JSON.stringify({id: activeUserId}), {
      //     path: '/',
      //     maxAge: 36000,
      //     sameSite: true,
      //   })
      //   await updateUserActiveUser(loggedInUserId);
      // }
      const fetchData = async () => {
        const data = await readUser(selectedUserId);
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('fetchData readUser(selectedUserId): data', data); // eslint-disable-line no-console
        }
        setSelectedUser(data);
      };
      fetchData().catch(console.error);
    }
  }, [selectedUserId]);


  useEffect(() => {
    console.log('this is the activeUserId is ', activeUserId);
    console.log('this is the activeUserProjectId is ', activeUserProjectId);
    console.log('this is the activeShellId is ', activeUserProjectId);
    console.log('this is the activeContentId is ', activeContentId);
  }, [activeUserId, activeUserProjectId, activeShellId, activeContentId]);
  /*
   * onLoad we get the userCreatedShells from the reactDivAPI and determine the menu items.
   * This hook is dependent on the userCreatedShells
   *
   * XXX When a new shell is created, a function readShells will run, this will update the data for userCreatedShells and trigger this hook to fire again.
   *
   */
  useEffect(() => {
    // readShells();
    // readFields();
    // readFieldSettings();
    console.log('project menu items', userCreatedProjects);
    if (userCreatedProjects.length > 0) {
      const projectsMenuItems = [];
      console.log('got here');
      userCreatedProjects.map((userCreatedProject, index) => {
        console.log('got also to here', userCreatedProject);
        projectsMenuItems.push({
          type: 'client',
          position: index,
          label: userCreatedProject.domain
        });
      });
      setUserCreatedProjectsMenuItems(projectsMenuItems);
    }

  }, [userCreatedProjects]);

  /*
   * onLoad we get the userCreatedShells from the reactDivAPI and determine the menu items.
   * This hook is dependent on the userCreatedShells
   *
   * XXX When a new shell is created, a function readShells will run, this will update the data for userCreatedShells and trigger this hook to fire again.
   *
   */
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('useEffect,[userCreatedShells]: userCreatedShells', userCreatedShells); // eslint-disable-line no-console
    }
    // if (userCreatedShells.length > 0) {
    //   const shellsMenuItems = [];
    //   console.log('got here');
    //   userCreatedShells.map((userCreatedShell, index) => {
    //     console.log('got also to here userCreatedShell', userCreatedShell);
    //     shellsMenuItems.push({
    //       name: userCreatedShell.singleApiId,
    //       label: userCreatedShell.name,
    //       destination: userCreatedShell.singleApiId,
    //     });
    //   });
    //   setUserCreatedShellsMenuItems(shellsMenuItems);
    // }

  }, [userCreatedShells]);

  const updateActiveFieldData = () => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateActiveFieldData:'), reactDivFieldSettings; // eslint-disable-line no-console
    }
    let settingsTabInputData;
    // let validationsTabInputData;
    // let advancedTabInputData;
    reactDivFieldSettings.map((fieldSettings, index) => {
      if (fieldSettings.name === activeReactDivField.name) {
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          // const fieldSetInputs = pickBy(optionsGroupData, (value, key) => key !== 'coreElement');
          logger.debug(
            'updateActiveFieldData the selected field is available: activeReactDivField',
            activeReactDivField,
            fieldSettings,
            // fieldSettings.properties.map(({id, ...rest}) => {
            //   return rest
            // })
          ); // eslint-disable-line no-console
        }
        settingsTabInputData = fieldSettings.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
      }
    });
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateActiveFieldData: settingsTabInputData', settingsTabInputData); // eslint-disable-line no-console
    }
    setActiveReactDivFieldSettings([settingsTabInputData]);
    // reactDivFieldValidations.map((fieldValidation, index) => {
    //   if (fieldValidation.name === activeReactDivField) {
    //     console.log('yes the name matches', fieldValidation);
    //     validationsTabInputData = fieldValidation.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
    //   }
    // });
    // console.log('testing ', validationsTabInputData);
    // setActiveReactDivFieldValidations(validationsTabInputData);
    //
    // reactDivFieldAdvanced.map((fieldAdvanced, index) => {
    //   if (fieldAdvanced.name === activeReactDivField) {
    //     console.log('yes the name matches', fieldAdvanced);
    //     advancedTabInputData = fieldAdvanced.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
    //   }
    // });
    // console.log('testing ', advancedTabInputData);
    // setActiveReactDivFieldAdvanced(advancedTabInputData);
  };

  /*
     * onLoad we skip the hook by relying on the default value `false` being set on the state variable `activeReactDivField`.
     * This field becomes active when a user clicks a field in the Dashboard.
     *
     * When the user selects a Field, the state will change causing the hook to fire again.
     *
     */
  useEffect(() => {
    if (activeReactDivField) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('activeReactDivField updateActiveFieldData()', activeReactDivField); // eslint-disable-line no-console
      }
      updateActiveFieldData();
    } else {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('activeReactDivField will be false on first load', activeReactDivField); // eslint-disable-line no-console
      }
    }
  }, [activeReactDivField]);

  // Project
  useEffect(() => {
    console.log('activeUserProjectId', activeUserProjectId);
    if (activeUserProjectId) {
      const updateData = async () => {
        const data = await updateUserActiveProject(activeUserId);
        console.log('useEffect updateData activeUserId data', data);
      };
      const fetchData = async () => {
        const data = await readProject(activeUserProjectId);
        console.log('useEffect fetchData activeUserProjectId data', data);
        setActiveUserProject(data);
        setLoggedInUserProject(data);
      };
      updateData().catch(console.error);
      fetchData().catch(console.error);
    }
  }, [activeUserProjectId]);

  // Content Entry
  useEffect(() => {
    console.log('activeContentId', activeContentId);

  }, [activeContentId]);

  const value = {
    // Users
    loggedInUserId,
    setLoggedInUserId,
    loggedInUser,
    setLoggedInUser,
    activeUserId,
    setActiveUserId,
    activeUser,
    setActiveUser,
    selectedUserId,
    setSelectedUserId,
    selectedUser,
    setSelectedUser,
    allUsersData,
    setAllUsersData,

    createUser,
    readUsers,
    readUser,
    updateUser,
    // allUsersData,
    // Web Module

    // Projects
    loggedInUserProjectId,
    setLoggedInUserProjectId,
    loggedInUserProject,
    setLoggedInUserProject,
    activeUserProjectId,
    setActiveUserProjectId,
    activeUserProject,
    setActiveUserProject,

    createProject,
    userCreatedProjects,
    userCreatedProjectsMenuItems,
    activeProject,
    setActiveProject,
    // Shells
    createShell,
    readShell,
    userCreatedShells,
    userCreatedShellsMenuItems,
    activeShellId,
    setActiveShellID,
    updateShellFields,
    // createSystemShell,

    // readSystemShell,
    activeSystemShellID,
    setActiveSystemShellID,
    // Fields
    reactDivFields,
    reactDivFieldSettings,
    reactDivFieldValidations,
    reactDivFieldAdvanced,
    activeReactDivField,
    setActiveReactDivField,
    activeReactDivFieldSettings,
    activeReactDivFieldValidations,
    activeReactDivFieldAdvanced,
    // Shelled Fields
    createShelledFields,
    allShelledFields,
    // createSystemShelledFields,
    // Dashboard
    currentAddReactionState,
    setCurrentAddReactionState,

    // Content
    createContentEntry,
    readContentEntry,
    updateContentEntry,
    updateContentEntryDependency,
    activeContentId,
    setActiveContentId,
  };

  return (
    <>
      <ReactDivAPIContext.Provider
        value={value}
      >
        {children}
      </ReactDivAPIContext.Provider>
    </>
  );

}
