import {ReactNode, useState, useEffect} from 'react';
import {userSessionContext as UserSessionContext} from '@/modules/core/userSession/contexts/userSessionContext';
import axios from 'axios';
import Router from 'next/router';
import {useCookies} from 'react-cookie';
import useReactDivAPI from '@/modules/core/reactDivAPI/hooks/useReactDivAPI';

type ProviderProps = {
  children: ReactNode;
}

export function UserSessionProvider({children}: ProviderProps) {

  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [cookie, setCookie, removeCookie] = useCookies([
    'access_token',
    'client_id',
    'loggedInUser',
    'cbd_club_stripe_id',
    'cbd_club_membership_details',
  ]);
  // @ts-ignore
  const {setLoggedInUser, setActiveUserId} = useReactDivAPI();

  const login = async (formData) => {

    // console.log('form submitted - formData: ', formData);

    // console.log('i am going to login one day', data)
    try {

      const response = await axios.post(process.env.NEXT_PUBLIC_REACTDIV_API_URL + 'reactDiv/web/user/login', {
        username: formData.username,
        password: formData.password,
      });
      const data = await response?.data;
      const accessToken = await  data?.access_token;
      const clientID = await data?.client_id;
      // await setLoggedInUser(clientID)
      // await setActiveUserId(prevState => ({...prevState, clientID}))
      await setUserLoggedIn(true);
      await setCookie('loggedInUser', JSON.stringify(data), {
        path: '/',
        maxAge: 36000,
        sameSite: true,
      });
      await setCookie('client_id', JSON.stringify(clientID), {
        path: '/',
        maxAge: 36000, // Expires after 10hr
        sameSite: true,
      });
      await setCookie('access_token', JSON.stringify(accessToken), {
        path: '/',
        maxAge: 360000, // Expires after 1hr
        sameSite: true,
      });

      // Return to home page when logged in and cookied.
      if (cookie.access_token) {
        await Router.push('/');
      } else {
        // const router: NextRouter = useRouter();
      }

    } catch (err) {
      // console.log(err);
    }

  };

  const logout = () => {
    setUserLoggedIn(false);
    removeCookie('access_token', {
      path: '/',
      sameSite: true,
    });
  };

  const value = {
    userLoggedIn,
    login,
    logout
  };

  return (
    <>
      <UserSessionContext.Provider
        value={value}
      >
        {children}
      </UserSessionContext.Provider>
    </>
  );

}
