import {ReactNode, useState} from 'react';
import {dashboardContext as DashboardContext} from '@/modules/core/data/contexts/dashboardContext';
import {useCookies} from 'react-cookie';
import useReactDivAPI from '@/modules/core/reactDivAPI/hooks/useReactDivAPI';

type DashboardProviderProps = {
  children: ReactNode;
}

export function DashboardProvider({
  children
}: DashboardProviderProps) {
  const [cookie, setCookie] = useCookies(['dashboard']);
  //@ts-ignore
  const {setSelectedUserId, selectedUserId} = useReactDivAPI();

  const [innerPanelMenuToggleState, setInnerPanelMenuToggleState] = useState<boolean>(false);
  const [extraPanelMenuToggleState, setExtraPanelMenuToggleState] = useState<boolean>(false);
  const [innerPanelMenuItemState, setInnerPanelMenuItemState] = useState<boolean>(false);

  // CRUD Dialogs
  const [dialogState, setDialogState] = useState({
    createDialog: false,
    editDialog: false,
  });
  const [updateDataLoaded, setUpdateDataLoaded] = useState(false);

  const handleDialogToggles = (dialogName, currentDialogState, userID) => {
    // console.log('dialogName', dialogName);
    // console.log('userID', userID);

    switch(dialogName) {
      case 'editDialog':
        setUpdateDataLoaded(false);
        setSelectedUserId(userID);
        setDialogState({
          ...currentDialogState,
          [dialogName]: !currentDialogState[dialogName]
        });
        break;
      case 'createDialog':
        setDialogState({
          ...currentDialogState,
          [dialogName]: !currentDialogState[dialogName]
        });
        break;
      default:
        // console.log('this case is not yet handled');
    }

  };

  const value = {
    dialogState,
    updateDataLoaded,
    setUpdateDataLoaded,
    handleDialogToggles,
    innerPanelMenuToggleState,
    setInnerPanelMenuToggleState,
    extraPanelMenuToggleState,
    setExtraPanelMenuToggleState,
    innerPanelMenuItemState,
    setInnerPanelMenuItemState
  };

  return (
    <>
      <DashboardContext.Provider
        value={value}
      >
        {children}
      </DashboardContext.Provider>
    </>
  );

}
