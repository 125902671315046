import React from 'react';
import {DashboardSemiPersistentSession} from '@/modules/core/data/types/DashboardSemiPersistentSession';

/**
 * The DashboardContext contains all DashboardSemiPersistentSession properties
 *
 * XXX "Partial" copies all properties from UserSemiPersistentSession and make them all optional
 *
 * @see https://stackoverflow.com/a/40076355/2391795
 * @see https://github.com/Microsoft/TypeScript/blob/ee25cdecbca49b2b5a290ecd65224f425b1d6a9c/lib/lib.es5.d.ts#L1354
 */
export type DashboardContext = Partial<DashboardSemiPersistentSession>

/**
 * Initial context, used by default until the Context Provider is initialised.
 *
 * @default Empty object, to allow for destructuring even when the context hasn't been initialised (on the server)
 */
const initialContext = {};

/**
 * Uses native React Context API
 *
 * @example Usage
 *  import dashboardContext from './src/stores/dashboardContext';
 *  const { locale, lang }: CustomerContext = React.useContext(customerContext);
 *
 * @see https://reactjs.org/docs/context.html
 * @see https://medium.com/better-programming/react-hooks-usecontext-30eb560999f for useContext hook example (open in anonymous browser #paywall)
 */
export const dashboardContext = React.createContext<DashboardContext>(initialContext);

export default dashboardContext;
