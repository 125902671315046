import { DateDay } from './types/DateDay';
import { Timestamp } from './types/Timestamp';
import {createLogger} from '@/modules/core/logging/logger';


const fileLabel = 'modules/core/date/date.ts';
const logger = createLogger({
  fileLabel,
});

/**
 * Adds the given years to the given date and returns the result.
 *
 * @param date
 * @param years
 */
export const addYears = (date: Date, years: number): Date => {
  return new Date(date.setFullYear(date.getFullYear() + years));
};

/**
 * Resolves the date of today and returns it in a DateDay format
 *
 * @example getTodayDate() => '2020-10-25'
 */
export const getTodayDate = (): DateDay => {
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTodayDate():'); // eslint-disable-line no-console
  }
  const now = new Date();
  const day = ('0' + now.getDate()).slice(-2);
  const month = ('0' + (now.getMonth() + 1)).slice(-2);

  return now.getFullYear() + '-' + (month) + '-' + (day);
};

/**
 * Resolves the date of today and returns it in a DateDay format
 *
 * @example getTodayDate() => '2020-10-25T08:45:32.55+00:00'
 */
export const getTimestampNow = (): Timestamp => {
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow():'); // eslint-disable-line no-console
  }
  const nowUnformatted = new Date();
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): nowUnformatted', nowUnformatted); // eslint-disable-line no-console
  }
  const day = ('0' + nowUnformatted.getDate()).slice(-2);
  let myDay: number | string = nowUnformatted.getDate();
  if (myDay < 10) {
    myDay = '0' + myDay;
  }
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): day, myDay', day, myDay); // eslint-disable-line no-console
  }
  const month = ('0' + (nowUnformatted.getMonth() + 1)).slice(-2);
  let myMonth: number | string = nowUnformatted.getMonth() + 1;
  if (myMonth < 10) {
    myMonth = '0' + myMonth;
  }
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): month, myMonth', month, myMonth); // eslint-disable-line no-console
  }
  const year = nowUnformatted.getFullYear();
  const myYear = nowUnformatted.getFullYear();
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): year, myYear', year, myYear); // eslint-disable-line no-console
  }
  let hours: number | string = nowUnformatted.getHours();
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): hours', hours); // eslint-disable-line no-console
  }
  let minutes: number | string = nowUnformatted.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): minutes', minutes); // eslint-disable-line no-console
  }
  const seconds = nowUnformatted.getSeconds();
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): seconds', seconds); // eslint-disable-line no-console
  }
  const milliseconds = nowUnformatted.getMilliseconds();
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('getTimestampNow(): milliseconds', milliseconds); // eslint-disable-line no-console
  }
  // return now.getFullYear() + '-' + (month) + '-' + (day);
  return nowUnformatted.getFullYear() + '-' + (month) + '-' + (day) + 'T' + (hours) + ':' + (minutes) + ':' + (seconds) + '.' + (milliseconds) + '+00:00';
  // console.log(year+'-' + month + '-'+day);
  // console.log(year+'-' + month + '-'+day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '+00:00');
  // return
  // console.log('time now', Date())
  // return
};

/**
 * Resolves whether the first dateDay is before the second dateDay.
 *
 * @example isDateDayBefore('2020-10-25', '2020-10-26') => true
 * @example isDateDayBefore('2020-10-25', '2020-10-24') => false
 *
 * @param dateDay
 * @param dateDay2
 */
export const isDateDayBefore = (dateDay: DateDay, dateDay2: DateDay): boolean => {
  const dateTime = new Date(dateDay).getTime();
  const dateTime2 = new Date(dateDay2).getTime();

  return dateTime < dateTime2;
};

/**
 * Display a date as an i18n string.
 *
 * @example toI18nString('2020-09-16', 'fr')
 * @example toI18nString('2020-09-16', 'en-US')
 * @example toI18nString('2020-09-16', 'en-US', { weekday: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' })
 *
 * @param dateDay
 * @param langOrLocale
 * @param options
 */
export const toI18nString = (dateDay: DateDay, langOrLocale: string, options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}): string => {
  const date = new Date(dateDay);

  return date.toLocaleDateString(langOrLocale, options);
};
