import React from 'react';
import AnimatedLoader from './AnimatedLoader';

type Props = {}

const Loader: React.FunctionComponent<Props> = (props): JSX.Element => {
  return (
    <div
      className="flex justify-center text-center mx-auto"
    >
      <AnimatedLoader />
    </div>
  );
};

export default Loader;
