import { MultiversalPageProps } from '@/layouts/web/types/MultiversalPageProps';
import { OnlyBrowserPageProps } from '@/layouts/web/types/OnlyBrowserPageProps';
import UniversalCookiesManager from '@/modules/core/cookiesManager/UniversalCookiesManager';
// import useCustomer from '@/modules/core/data/hooks/useCustomer';
// import useDataset from '@/modules/core/data/hooks/useDataset';
// import { Customer } from '@/modules/core/data/types/Customer';
import { detectLightHouse } from '@/modules/core/lightHouse/lighthouse';
import { createLogger } from '@/modules/core/logging/logger';
import {
  ClientNetworkConnectionType,
  ClientNetworkInformationSpeed,
  getClientNetworkConnectionType,
  getClientNetworkInformationSpeed,
} from '@/modules/core/networkInformation/networkInformation';
import { configureSentryBrowserMetadata } from '@/modules/core/sentry/browser';
import { configureSentryUserMetadata } from '@/modules/core/sentry/universal';
import { cypressContext } from '@/modules/core/testing/contexts/cypressContext';
import {
  CYPRESS_WINDOW_NS,
  detectCypress,
} from '@/modules/core/testing/cypress';
import userConsentContext from '@/modules/core/userConsent/contexts/userConsentContext';
import initCookieConsent, { getUserConsent } from '@/modules/core/userConsent/cookieConsent';
import { UserConsent } from '@/modules/core/userConsent/types/UserConsent';
import { UserSemiPersistentSession } from '@/modules/core/userSession/types/UserSemiPersistentSession';
import { userSessionContext } from '@/modules/core/userSession/contexts/userSessionContext';
import {
  getIframeReferrer,
  isRunningInIframe,
} from '@/utils/iframe';
import * as Sentry from '@sentry/node';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { MultiversalAppBootstrapPageProps } from '../types/MultiversalAppBootstrapPageProps';
import { MultiversalAppBootstrapProps } from '../types/MultiversalAppBootstrapProps';

const fileLabel = 'app/components/BrowserPageBootstrap';
const logger = createLogger({
  fileLabel,
});

export type BrowserPageBootstrapProps = MultiversalAppBootstrapProps<MultiversalPageProps & MultiversalAppBootstrapPageProps>;

/**
 * Bootstraps the page, only when rendered on the browser
 *
 * @param props
 */
const BrowserPageBootstrap = (props: BrowserPageBootstrapProps): JSX.Element => {
  const {
    //@ts-ignore
    session,
    Component,
    err,
    router,
  } = props;
  // When the page is served by the browser, some browser-only properties are available
  const pageProps = props.pageProps as unknown as MultiversalPageProps<OnlyBrowserPageProps>;
  const {
    // customerRef = "customerRef",
    lang,
    locale,
  } = pageProps;
  // const {
  //   t,
  //   i18n,
  // } = useTranslation();
  // const dataset = useDataset();
  // const customer: Customer = useCustomer();
  const isInIframe: boolean = isRunningInIframe();
  const iframeReferrer: string = getIframeReferrer();
  const cookiesManager: UniversalCookiesManager = new UniversalCookiesManager(); // On browser, we can access cookies directly (doesn't need req/res or page context)
  const userSession: UserSemiPersistentSession = cookiesManager.getUserData();

  const userId = userSession.id;
  // @ts-ignore
  const injectedPageProps: MultiversalPageProps<OnlyBrowserPageProps> = {
    ...props.pageProps,
    isInIframe,
    iframeReferrer,
    cookiesManager,
    userSession,
  };
  // const theme = useTheme();
  const isCypressRunning = detectCypress();
  const isLightHouseRunning = detectLightHouse();
  const networkSpeed: ClientNetworkInformationSpeed = getClientNetworkInformationSpeed();
  const networkConnectionType: ClientNetworkConnectionType = getClientNetworkConnectionType();

  // Configure Sentry user and track navigation through breadcrumb
  configureSentryUserMetadata(userSession);
  configureSentryBrowserMetadata(networkSpeed, networkConnectionType, isInIframe, iframeReferrer);

  Sentry.addBreadcrumb({ // See https://docs.sentry.io/enriching-error-data/breadcrumbs
    category: fileLabel,
    message: `Rendering ${fileLabel}`,
    // level: Sentry.Severity.Debug,
    level: 'debug',
  });

  const userConsent: UserConsent = getUserConsent();
  const {
    isUserOptedOutOfAnalytics,
    hasUserGivenAnyCookieConsent,
  } = userConsent;
  // @ts-ignore


  // Init the Cookie Consent popup, which will open on the browser
  // @ts-ignore
  initCookieConsent({
    allowedPages: [ // We only allow it on those pages to avoid display that boring popup on every page
      `${window.location.origin}/${locale}/demo/terms`,
      `${window.location.origin}/${locale}/demo/privacy`,
      `${window.location.origin}/${locale}/demo/built-in-features/cookies-consent`,
    ],
    locale,
    // t,
    // theme,
    userConsent,
  });

  // XXX Inject data so that Cypress can use them to run dynamic tests.
  //  Those data mustn't be sensitive. They'll be available in the DOM, no matter the stage of the app.
  //  This is needed to run E2E tests that are specific to a customer. (dynamic testing)
  // window[CYPRESS_WINDOW_NS] = {
  //   dataset,
  //   customer,
  // };

  // In non-production stages, bind some utilities to the browser's DOM, for ease of quick testing
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    // window['i18n'] = i18n;
    // @ts-ignore
    window['router'] = router;
    // window['t'] = t;
    logger.info(`Utilities have been bound to the DOM for quick testing (only in non-production stages):
        - i18n
        - router
        - t
    `);
  }

  return (
    <>
      {/* @ts-ignore */}
      <cypressContext.Provider value={{ isCypressRunning }}>

        {/*<userSessionContext.Provider value={{ ...userSession }}>*/}
        <userConsentContext.Provider value={{ ...userConsent }}>
          <Component
            {...injectedPageProps}
            // @ts-ignore
            error={err}
          />
        </userConsentContext.Provider>
        {/*</userSessionContext.Provider>*/}

      </cypressContext.Provider>
    </>
  );
};

export default BrowserPageBootstrap;
