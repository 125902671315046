import {ReactNode, useState, useEffect} from 'react';
import {cbdClubContext as CBDClubContext} from '@/modules/core/cbdClub/contexts/cbdClubContext';
import axios from 'axios';
import Router from 'next/router';
import {useCookies} from 'react-cookie';
import useReactDivAPI from '@/modules/core/reactDivAPI/hooks/useReactDivAPI';
import {createLogger} from "@/modules/core/logging/logger";


const fileLabel = 'modules/core/cbdClub/providers/cbdClubProvider.tsx';
const logger = createLogger({ // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
  fileLabel,
});

type ProviderProps = {
  children: ReactNode;
}

export function CbdClubProvider({children}: ProviderProps) {

  const [cookie, setCookie] = useCookies([
    'cbd_club_stripe_id',
    'cbd_club_membership_details',
  ]);

  const setStripeID = async (subscriptionID) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('CbdClubProvider setStripeID subscriptionID', subscriptionID); // eslint-disable-line no-console
    }
    await setCookie('cbd_club_stripe_id', JSON.stringify(subscriptionID), {
      path: '/',
      maxAge: 3600,
      sameSite: true
    })
  }

  const collectMembers = async (formData, membership) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('CbdClubProvider collectMembers formData', formData); // eslint-disable-line no-console
      logger.info('CbdClubProvider collectMembers membership', membership); // eslint-disable-line no-console
    }
    switch(membership) {
      case 'single':
        await setCookie('cbd_club_membership_details', JSON.stringify({
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          membership: membership
        }), {
          path: '/',
          maxAge: 3600,
          sameSite: true
        })
        break;
      case 'couple':
        await setCookie('cbd_club_membership_details', JSON.stringify({
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          membership: membership,
          guests: [
            {
              firstName: formData.guest_firstname,
              lastName: formData.guest_lastname,
              email: formData.guest_email && formData.guest_email || undefined
            }
          ]
        }), {
          path: '/',
          maxAge: 3600,
          sameSite: true
        })
        break;
      case 'family':
        await setCookie('cbd_club_membership_details', JSON.stringify({
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          membership: membership,
          guests: [
            {
              firstName: formData.guest_firstname,
              lastName: formData.guest_lastname,
              email: formData.guest_email && formData.guest_email || undefined
            },
            {
              firstName: formData.guest_two_firstname,
              lastName: formData.guest_two_lastname,
              email: formData.guest_two_email && formData.guest_two_email || undefined
            }
          ]
        }), {
          path: '/',
          maxAge: 3600,
          sameSite: true
        })
    }
  }

  const storeMembership = async (stripeData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('CbdClubProvider storeMembership stripeData', stripeData); // eslint-disable-line no-console
      logger.info('CbdClubProvider storeMembership cookie', cookie.cbd_club_membership_details); // eslint-disable-line no-console
    }
    console.log('chickensrunning', stripeData, cookie.cbd_club_membership_details)
    const now = new Date()
    let utc_brisbane = new Date(now.getTime() + (600*60000));
    const herokuResponse = await axios.post(process.env.NEXT_PUBLIC_CBDCLUB_API_URL + 'central_brisbane_dental/member/create', {
      "profile": {
        "firstName": cookie.cbd_club_membership_details.first_name,
        "lastName": cookie.cbd_club_membership_details.last_name,
        "email": cookie.cbd_club_membership_details.email,
        "phone": stripeData.customer_details.phone,
        "membership": cookie.cbd_club_membership_details.membership,
        "guests": cookie.cbd_club_membership_details.guests && cookie.cbd_club_membership_details.guests || undefined
        // `${guests.length > 0 ? guests.map((guest,
        //   index) => (
        //   `Guest ${index + 1}: ${guest.first_name} ${guest.last_name}\n\n`
        // )) : 'No guests'}`
      },
      "billing": {
        "customer_stripe_id": stripeData.customer,
        "start_date": utc_brisbane
      }
      // billing: {}
    });
    console.log("chickensrunningmore", herokuResponse)
    const data = await herokuResponse?.data;
    // firstName: string;
    // lastName: string;
    // phone?: string;
    // email: string;
    // membership: string;
    // guests?: any;
    if (data) {
      console.log('chickensrunningbefore')
      const emailAPIResponse = await axios.post(
        '/api/new_member',
        {
          firstName: cookie.cbd_club_membership_details.first_name,
          lastName: cookie.cbd_club_membership_details.last_name,
          phone: stripeData.customer_details.phone,
          email: cookie.cbd_club_membership_details.email,
          membership: cookie.cbd_club_membership_details.membership,
          guests: cookie.cbd_club_membership_details.guests && cookie.cbd_club_membership_details.guests || []
          // guests: cookie.cbd_club_membership_details.guests && cookie.cbd_club_membership_details || [{
          //   first_name: "Vincent",
          //   last_name: "Wan"
          // }, {first_name: "Dorothy", last_name: "Wan"}]
        }
      );
      console.log('chickensrunning even more', emailAPIResponse)
    }
  }

  const value = {
    setStripeID,
    collectMembers,
    storeMembership
  };

  return (
    <>
      <CBDClubContext.Provider
        value={value}
      >
        {children}
      </CBDClubContext.Provider>
    </>
  );

}
